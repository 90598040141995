/*global $ */
import React, { Component } from 'react';

import database from '../../database/database';
import PosterList from '../poster-list/poster-list';

class App extends Component {
    static propTypes = {};

    constructor(props) {
        super(props);

        var urlParams = new URLSearchParams(window.location.search);
        var staticList = urlParams.get('static') != null;

        this.state = {
            containerHeight: $('#postersContainer').height(),
            filter: '',
            posterRowCount: 1,
            posterWidth: 180,
            scrollTop: 0,
            staticList,
            tab: 'movies'
        };

        database.sort((a, b) => {
            const aText = a.name.toLowerCase();
            const bText = b.name.toLowerCase();

            if (aText < bText) {
                return -1;
            } else if (aText > bText) {
                return 1;
            } else {
                return 0;
            }
        });

        this.movies = [];
        this.tvShows = [];
        database.forEach((item) => {
            var { movies, name, tvShows, type } = item;
            if (type === 'movieFranchise') {
                this.movies.push({ type: 'franchiseTitle', name, franchiseItems: movies });
                movies.forEach((movie) => this.movies.push({ ...movie, franchiseName: name, type: 'movieFromFranchise' }));
            } else if (type === 'movie') {
                this.movies.push(item);
            } else if (type === 'tvShowFranchise') {
                this.tvShows.push({ type: 'franchiseTitle', name, franchiseItems: tvShows });
                tvShows.forEach((tvShow) => this.tvShows.push({ ...tvShow, franchiseName: name, type: 'tvShowFromFranchise' }));
            } else if (type.startsWith('tvShow')) {
                this.tvShows.push(item);
            } else {
                console.log('Unknown item type:', item);
            }
        });

        $('#moviesTab').on('click', () => this.changeTab('movies'));
        $('#tvShowsTab').on('click', () => this.changeTab('tvShows'));
        $('#posterSizes > .posterSize').on('click', (event) => this.changePosterSize(event));
        $('#filterInput').on('keyup', (event) => this.setState({ filter: $(event.target).val().trim().toLowerCase() }));
        $('#postersContainer').on('scroll', () => this.setState({ scrollTop: $('#postersContainer').scrollTop() }));
    }

    componentDidMount() {
        $('#posterSizes > .posterSize').eq(4).trigger('click'); // 7 per row
    }

    changeTab(tab) {
        var { tab: currentTab } = this.state;
        var slider = $('#slider');

        if (tab !== currentTab) {
            slider.removeClass(currentTab).addClass(tab);
            this.setState({ tab });
        }
    }

    changePosterSize(event) {
        var postersList = $('#postersList');
        var posterSizes = $('#posterSizes > .posterSize');
        posterSizes.removeClass('selected');
        $(event.target).addClass('selected');

        var posterRowCount = parseInt($(event.target).html());
        var posterWidth = (postersList.width() / posterRowCount) - 20;
        this.setState({ posterRowCount, posterWidth });
    }

    filterFunc(item) {
        var { filter } = this.state;
        var { attributes, franchiseItems, franchiseName = '', name, type } = item;

        attributes = (attributes || []).map((attribute) => attribute.toLowerCase());

        if (name.toLowerCase().includes(filter) || franchiseName.toLowerCase().includes(filter) || attributes.includes(filter)) {
            return true;
        }

        if (type === 'franchiseTitle') {
            return (franchiseItems || []).some((item) => this.filterFunc(item));
        }

        return false;
    }

    render() {
        var { movies, tvShows } = this;
        var { filter, posterRowCount, posterWidth, scrollTop, staticList, tab } = this.state;

        var _movies = movies;
        var _tvShows = tvShows;
        if (filter) {
            _movies = movies.filter((item) => this.filterFunc(item));
            _tvShows = tvShows.filter((item) => this.filterFunc(item));
        }

        var posterHeight = (posterWidth * 1.5) + 33/* title (14,14,5) */ + 25 /* padding */;
        var posterWidthTotal = posterWidth + 20;
        var numberOfMovies = _movies.reduce((a, item) => item.type === 'movieFranchise' ? a + item.movies.length + 1 : a + 1, 0);
        var numberOfTvShows = _tvShows.reduce((a, item) => item.type === 'tvShowFranchise' ? a + item.tvShows.length + 1 : a + 1, 0);
        var numberOfItems = tab === 'movies' ? numberOfMovies : numberOfTvShows;
        var numberOfRows = Math.ceil(numberOfItems / posterRowCount);

        var startingIndex = 0;
        var visibleItemCount = Math.max(numberOfMovies, numberOfTvShows);

        if (true || !staticList) {
            startingIndex = Math.max(Math.floor(scrollTop / posterHeight) - 2, 0) * posterRowCount;
            visibleItemCount = (Math.ceil($('#postersContainer').height() / posterHeight) + 4) * posterRowCount;
        }


        var moviesCount = _movies.filter((movie) => movie.type !== 'franchiseTitle').length;
        var tvShowsCount = _tvShows.filter((tvShow) => tvShow.type !== 'franchiseTitle').length;

        _movies = _movies.slice(startingIndex, startingIndex + visibleItemCount);
        _tvShows = _tvShows.slice(startingIndex, startingIndex + visibleItemCount);

        return (
            <React.Fragment>
                <div style={{ color: '#fff' }}>{moviesCount} movies and {tvShowsCount} tv shows</div>
                <div id='postersList' style={{ height: posterHeight * numberOfRows }}>
                    <PosterList
                        data={_movies}
                        listType='movies'
                        posterHeight={posterHeight}
                        posterRowCount={posterRowCount}
                        posterWidth={posterWidth}
                        posterWidthTotal={posterWidthTotal}
                        startingIndex={startingIndex}
                        visible={tab === 'movies'}
                    />
                    <PosterList
                        data={_tvShows}
                        listType='tvShows'
                        posterHeight={posterHeight}
                        posterRowCount={posterRowCount}
                        posterWidth={posterWidth}
                        posterWidthTotal={posterWidthTotal}
                        startingIndex={startingIndex}
                        visible={tab === 'tvShows'}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default App;
