import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MoviePoster from '../movie-poster/movie-poster';
import TvShowPoster from '../tv-show-poster/tv-show-poster';
import './poster-list.scss';

class PosterList extends Component {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.object).isRequired,
        listType: PropTypes.oneOf(['movies', 'tvShows']).isRequired,
        posterWidth: PropTypes.number.isRequired,
        visbile: PropTypes.bool,
    };

    static defaultProps = {
        visible: true,
    };

    state = {

    };

    render() {
        var { data, listType, posterHeight, posterRowCount, posterWidth, posterWidthTotal, startingIndex, visible } = this.props;

        var style = visible ? {} : { display: 'none' };
        var PosterComponent = listType === 'movies' ? MoviePoster : TvShowPoster;

        var i = startingIndex - 1;
        return (
            <div id={`${listType}List`} style={style}>
                {data.map((item) => {
                    i++;
                    var rowNumber = Math.floor(i / posterRowCount);
                    var columnNumber = i % posterRowCount;

                    if (item.type === 'franchiseTitle') {
                        var franchiseType = listType === 'movies' ? 'movie' : 'tvShow';
                        return (
                            <div
                                className={`${franchiseType} franchiseTitle`}
                                key={`franchise-${item.name}`}
                                style={{
                                    height: posterHeight - 25,
                                    left: posterWidthTotal * columnNumber,
                                    top: posterHeight * rowNumber,
                                    width: posterWidth,
                                }}
                            >
                                <div className='name'>{item.name}</div>
                            </div>
                        );
                    } else {
                        return (
                            <PosterComponent
                                franchise={item.type.includes('FromFranchise')}
                                key={`${item.name}-${item.year}`}
                                left={posterWidthTotal * columnNumber}
                                posterHeight={posterHeight}
                                posterWidth={posterWidth}
                                top={posterHeight * rowNumber}
                                {...item}
                            />
                        );
                    }
                })}
            </div>
        );
    }
}

export default PosterList;
