import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class TvShowPoster extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        origin: PropTypes.string.isRequired,
        posterWidth: PropTypes.number.isRequired,
        seasons: PropTypes.array.isRequired,
    };

    tvShowToImageName(name) {
        let newName = name.toLowerCase().replace(/[^a-z0-9\s]/g, '');
        newName = newName.replace(/\s+/g, '-');

        return `${newName}.jpg`;
    }

    getSeasonsString(seasons, filterFunction) {
        let filteredSeasons = seasons.filter(filterFunction).map((season) => season.number);

        if (this.isInConsecutiveOrder(filteredSeasons)) {
            filteredSeasons = `${filteredSeasons[0]}-${filteredSeasons[filteredSeasons.length - 1]}`;
        } else {
            filteredSeasons = filteredSeasons.join(', ');
        }

        return filteredSeasons;
    }

    isInConsecutiveOrder(numbers) {
        if (numbers.length > 1) {
            for (let i = 0; i < numbers.length; i++) {
                if (numbers[i] !== (i + numbers[0])) {
                    return false;
                }
            }
            return true;
        } else {
            return false;
        }
    }

    render() {
        var { franchise, left, name, origin, posterHeight, posterWidth, seasons, top } = this.props;

        var _className = classnames('tvShow', { franchise });

        var complete = this.getSeasonsString(seasons, (season) => season.downloadStatus === 'complete');
        var incomplete = this.getSeasonsString(seasons, (season) => season.downloadStatus === 'incomplete' && season.episodes);
        var notDownloaded = this.getSeasonsString(seasons, (season) => season.episodes === null);

        return (
            <div className={_className} style={{ height: posterHeight - 25, left, top, width: posterWidth }}>
                <div className='poster'>
                    <img src={`posters/tv-posters-tiny-png/${this.tvShowToImageName(name)}`} alt='' />
                    <div className='info'>
                        <span style={{ fontSize: '20px', marginBottom: '15px' }}>{name}</span>
                        <span>Origin: {origin}</span>
                        <span>Seasons: {complete}</span>
                        {incomplete ? <span>Incomplete: {incomplete}</span> : null}
                        {notDownloaded ? <span>Not Downloaded: {notDownloaded}</span> : null}
                    </div>
                </div>
                <div className='name'>{name}</div>
            </div>
        );
    }
}

export default TvShowPoster;
