var database = [

    // Movie Franchises
    { type: 'movieFranchise', name: 'American Pie', order: 'series', movies: [
        { origin: 'downloaded', name: 'American Pie', year: 1999 },
        { origin: 'downloaded', name: 'American Pie 2', year: 2001 },
        { origin: 'downloaded', name: 'American Wedding', year: 2003 },
        { origin: 'downloaded', name: 'American Reunion', year: 2012 },
        { origin: 'downloaded', name: 'American Pie Presents Band Camp', year: 2005 },
        { origin: 'downloaded', name: 'American Pie Presents The Naked Mile', year: 2006 },
        { origin: 'downloaded', name: 'American Pie Presents Beta House', year: 2007 },
        { origin: 'downloaded', name: 'American Pie Presents The Book of Love', year: 2009 },
        { origin: 'downloaded', name: 'American Pie Presents Girls Rules', year: 2020 },
    ] },
    { type: 'movieFranchise', name: 'Back to the Future', order: 'series', movies: [
        { origin: 'downloaded', name: 'Back to the Future', year: 1985 },
        { origin: 'downloaded', name: 'Back to the Future Part II', year: 1989 },
        { origin: 'downloaded', name: 'Back to the Future Part III', year: 1990 },
    ] },
    { type: 'movieFranchise', name: 'Bad Boys', order: 'series', movies: [
        { origin: 'downloaded', name: 'Bad Boys', year: 1995 },
        { origin: 'downloaded', name: 'Bad Boys II', year: 2003 },
        { origin: 'downloaded', name: 'Bad Boys For Life', year: 2020 },
    ] },
    { type: 'movieFranchise', name: 'Batman', order: 'series', movies: [
        { origin: 'downloaded', name: 'Batman Begins', year: 2005 },
        { origin: 'downloaded', name: 'The Dark Knight', year: 2008 },
        { origin: 'downloaded', name: 'The Dark Knight Rises', year: 2012 },
    ] },
    { type: 'movieFranchise', name: 'Blade', order: 'series', movies: [
        { origin: 'downloaded', name: 'Blade', year: 1998 },
        { origin: 'downloaded', name: 'Blade II', year: 2002 },
        { origin: 'downloaded', name: 'Blade Trinity', year: 2004 },
    ] },
    { type: 'movieFranchise', name: 'Blade Runner', order: 'series', movies: [
        { origin: 'downloaded', name: 'Blade Runner', year: 1982 },
        { origin: 'downloaded', name: 'Blade Runner 2049', year: 2017 },
    ] },
    { type: 'movieFranchise', name: 'Bourne', order: 'series', movies: [
        { origin: 'downloaded|purchased', name: 'The Bourne Identity', year: 2002 },
        { origin: 'downloaded', name: 'The Bourne Supremacy', year: 2004 },
        { origin: 'downloaded', name: 'The Bourne Ultimatum', year: 2007 },
        { origin: 'downloaded', name: 'The Bourne Legacy', year: 2012 },
        { origin: 'downloaded', name: 'Jason Bourne', year: 2016 },
    ] },
    { type: 'movieFranchise', name: 'Creed', order: 'series', movies: [
        { origin: 'downloaded', name: 'Creed', year: 2015 },
        { origin: 'downloaded', name: 'Creed II', year: 2018 },
    ] },
    { type: 'movieFranchise', name: 'DC Extended Universe', order: 'series', movies: [
        { origin: 'downloaded', name: 'Man of Steel', year: 2013 },
        { origin: 'downloaded', name: 'Suicide Squad', year: 2016 },
        { origin: 'downloaded', name: 'Batman v Superman - Dawn of Justice - Ultimate Edition', year: 2016 },
        { origin: 'downloaded', name: 'Wonder Woman', year: 2017 },
        { origin: 'downloaded', name: 'Justice League', year: 2017, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Aquaman', year: 2018 },
        { origin: 'downloaded', name: 'Shazam!', year: 2019, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Birds Of Prey And The Fantabulous Emancipation Of One Harley Quinn', year: 2020 },
        { origin: 'downloaded', name: 'Wonder Woman 1984', year: 2020, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Zack Snyder\'s Justice League', year: 2021, attributes: ['4K'] },
        { origin: 'downloaded', name: 'The Suicide Squad', year: 2021, attributes: ['4K'] },
    ] },
    { type: 'movieFranchise', name: 'Disney/Pixar', order: 'alphabetical', movies: [
        { origin: 'downloaded', name: 'Aladdin', year: 1992 },
        { origin: 'downloaded', name: 'Aladdin', year: 2019 },
        { origin: 'downloaded', name: 'Big Hero 6', year: 2014 },
        { origin: 'downloaded', name: 'Brave', year: 2012 },
        { origin: 'downloaded', name: 'Cars', year: 2006 },
        { origin: 'downloaded', name: 'Cars 2', year: 2011 },
        { origin: 'downloaded', name: 'Cars 3', year: 2017 },
        { origin: 'downloaded', name: 'Coco', year: 2017 },
        { origin: 'downloaded', name: 'Cruella', year: 2021 },
        { origin: 'downloaded', name: 'Dumbo', year: 2019 },
        { origin: 'downloaded', name: 'Finding Dory', year: 2016 },
        { origin: 'downloaded', name: 'Finding Nemo', year: 2003 },
        { origin: 'downloaded', name: 'Frozen', year: 2013, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Frozen II', year: 2019, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Inside Out', year: 2015 },
        { origin: 'downloaded', name: 'Jungle Cruise', year: 2021, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Maleficent', year: 2014 },
        { origin: 'downloaded', name: 'Maleficent - Mistress Of Evil', year: 2019 },
        { origin: 'downloaded', name: 'Moana', year: 2016, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Mulan', year: 2020, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Onward', year: 2020 },
        { origin: 'downloaded|purchased', name: 'Ralph Breaks the Internet', year: 2018 },
        { origin: 'downloaded', name: 'Ratatouille', year: 2007 },
        { origin: 'downloaded', name: 'Raya and the Last Dragon', year: 2021, attributes: ['4K'] },
        { origin: 'downloaded', name: 'The Incredibles', year: 2004 },
        { origin: 'downloaded|purchased', name: 'The Incredibles 2', year: 2018 },
        { origin: 'downloaded', name: 'The Jungle Book', year: 2016 },
        { origin: 'downloaded', name: 'The Lion King', year: 1994 },
        { origin: 'downloaded', name: 'The Lion King', year: 2019 },
        { origin: 'downloaded', name: 'Toy Story 4', year: 2019 },
        { origin: 'downloaded', name: 'Up', year: 2009 },
        { origin: 'downloaded', name: 'Wall-E', year: 2008 },
        { origin: 'downloaded', name: 'Wreck it Ralph', year: 2012 },
        { origin: 'downloaded', name: 'Zootopia', year: 2016 },
    ] },
    { type: 'movieFranchise', name: 'Divergent', order: 'series', movies: [
        { origin: 'downloaded', name: 'Divergent', year: 2014 },
        { origin: 'downloaded', name: 'Insurgent', year: 2015 },
        { origin: 'downloaded', name: 'Allegiant', year: 2016 },
    ] },
    { type: 'movieFranchise', name: 'Fallen', order: 'series', movies: [
        { origin: 'downloaded', name: 'Olympus Has Fallen', year: 2013 },
        { origin: 'downloaded', name: 'London Has Fallen', year: 2016 },
        { origin: 'downloaded', name: 'Angel Has Fallen', year: 2019 },
    ] },
    { type: 'movieFranchise', name: 'Fast and Furious', order: 'series', movies: [
        { origin: 'downloaded', name: 'The Fast and The Furious', year: 2001 },
        { origin: 'downloaded', name: '2 Fast 2 Furious', year: 2003 },
        { origin: 'downloaded', name: 'Fast and Furious - Tokyo Drift', year: 2006 },
        { origin: 'downloaded', name: 'Fast & Furious 4', year: 2009 },
        { origin: 'downloaded', name: 'Fast Five', year: 2011 },
        { origin: 'downloaded', name: 'Furious 6', year: 2013 },
        { origin: 'downloaded', name: 'Fast and Furious 7', year: 2015 },
        { origin: 'downloaded', name: 'The Fate of the Furious', year: 2017 },
        { origin: 'downloaded', name: 'Hobbs & Shaw', year: 2019 },
        { origin: 'downloaded', name: 'Fast and Furious F9', year: 2021, attributes: ['4K'] },
    ] },
    { type: 'movieFranchise', name: 'Fifty Shades of Grey', order: 'series', movies: [
        { origin: 'downloaded', name: 'Fifty Shades of Grey', year: 2015 },
        { origin: 'downloaded', name: 'Fifty Shades Darker', year: 2017 },
        { origin: 'downloaded', name: 'Fifty Shades Freed', year: 2018 },
    ] },
    { type: 'movieFranchise', name: 'Ghostbusters', order: 'series', movies: [
        { origin: 'downloaded', name: 'Ghostbusters', year: 1984 },
        { origin: 'downloaded', name: 'Ghostbusters II', year: 1989 },
        { origin: 'downloaded', name: 'Ghostbusters', year: 2016 },
    ] },
    { type: 'movieFranchise', name: 'Harold and Kumar', order: 'series', movies: [
        { origin: 'downloaded', name: 'Harold and Kumar Go To White Castle', year: 2004 },
        { origin: 'downloaded', name: 'Harold and Kumar Escape From Guantanamo Bay', year: 2008 },
        { origin: 'downloaded', name: 'A Very Harold And Kumar Christmas', year: 2011 },
    ] },
    { type: 'movieFranchise', name: 'Harry Potter', order: 'series', movies: [
        { origin: 'downloaded', name: 'Harry Potter and the Sorcerers Stone', year: 2001 },
        { origin: 'downloaded', name: 'Harry Potter and the Chamber of Secrets', year: 2002 },
        { origin: 'downloaded', name: 'Harry Potter and the Prisoner of Azkaban', year: 2004 },
        { origin: 'downloaded', name: 'Harry Potter and the Goblet of Fire', year: 2005 },
        { origin: 'downloaded', name: 'Harry Potter and the Order of the Phoenix', year: 2007 },
        { origin: 'downloaded', name: 'Harry Potter and the Half-Blood Prince', year: 2009 },
        { origin: 'downloaded', name: 'Harry Potter and the Deathly Hallows Part 1', year: 2010 },
        { origin: 'downloaded', name: 'Harry Potter and the Deathly Hallows Part 2', year: 2011 },
    ] },
    { type: 'movieFranchise', name: 'How To Train Your Dragon', order: 'series', movies: [
        { origin: 'downloaded', name: 'How To Train Your Dragon', year: 2010 },
        { origin: 'downloaded', name: 'How to Train Your Dragon 2', year: 2014 },
        { origin: 'downloaded', name: 'How To Train Your Dragon - The Hidden World', year: 2019 },
    ] },
    { type: 'movieFranchise', name: 'I Know What You Did Last Summer', order: 'series', movies: [
        { origin: 'downloaded', name: 'I Know What You Did Last Summer', year: 1997 },
        { origin: 'downloaded', name: 'I\'ll Always Know What You Did Last Summer', year: 2006 },
    ] },
    { type: 'movieFranchise', name: 'John Wick', order: 'series', movies: [
        { origin: 'downloaded', name: 'John Wick', year: 2014, attributes: ['4K'] },
        { origin: 'downloaded', name: 'John Wick Chapter 2', year: 2017, attributes: ['4K'] },
        { origin: 'downloaded', name: 'John Wick Chapter 3 - Parabellum', year: 2019, attributes: ['4K'] },
    ] },
    { type: 'movieFranchise', name: 'Jumanji', order: 'series', movies: [
        { origin: 'downloaded', name: 'Jumanji', year: 1995 },
        { origin: 'downloaded|purchased', name: 'Jumanji - Welcome to the Jungle', year: 2017 },
        { origin: 'downloaded', name: 'Jumanji - The Next Level', year: 2019 },
    ] },
    { type: 'movieFranchise', name: 'Leaving Neverland', order: 'series', movies: [
        { origin: 'downloaded', name: 'Part I', year: 2019 },
        { origin: 'downloaded', name: 'Part II', year: 2019 },
    ] },
    { type: 'movieFranchise', name: 'Marvel Cinematic Universe', order: 'series', movies: [
        { origin: 'downloaded|purchased', name: 'Iron Man', year: 2008, attributes: ['4K'] },
        { origin: 'downloaded', name: 'The Incredible Hulk', year: 2008, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Iron Man 2', year: 2010, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Thor', year: 2011, attributes: ['4K'] },
        { origin: 'downloaded|purchased', name: 'Captain America - The First Avenger', year: 2011, attributes: ['4K'] },
        { origin: 'downloaded', name: 'The Avengers', year: 2012, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Iron Man 3', year: 2013, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Thor - The Dark World', year: 2013, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Captain America - The Winter Soldier', year: 2014, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Guardians of the Galaxy', year: 2014, attributes: ['4K'] },
        { origin: 'downloaded|purchased', name: 'The Avengers - Age of Ultron', year: 2015, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Ant-Man', year: 2015, attributes: ['4K'] },
        { origin: 'downloaded|purchased', name: 'Captain America - Civil War', year: 2016, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Doctor Strange', year: 2016, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Guardians of The Galaxy Vol. 2', year: 2017 },
        { origin: 'downloaded', name: 'Spider-Man - Homecoming', year: 2017, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Thor - Ragnarok', year: 2017, attributes: ['4K'] },
        { origin: 'downloaded|purchased', name: 'Black Panther', year: 2018, attributes: ['4K'] },
        { origin: 'downloaded|purchased', name: 'Avengers - Infinity War', year: 2018, attributes: ['4K'] },
        { origin: 'downloaded|purchased', name: 'Ant-Man and the Wasp', year: 2018, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Captain Marvel', year: 2019, attributes: ['4K'] },
        { origin: 'downloaded|purchased', name: 'Avengers - Endgame', year: 2019, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Spider-Man - Far From Home', year: 2019, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Black Widow', year: 2021, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Shang-Chi and the Legend of the Ten Rings', year: 2021, attributes: ['4K'] },
    ] },
    { type: 'movieFranchise', name: 'Mission Impossible', order: 'series', movies: [
        { origin: 'downloaded', name: 'Mission Impossible', year: 1996 },
        { origin: 'downloaded', name: 'Mission Impossible 2', year: 2000 },
        { origin: 'downloaded', name: 'Mission Impossible 3', year: 2006 },
        { origin: 'downloaded', name: 'Mission Impossible - Ghost Protocol', year: 2011 },
        { origin: 'downloaded', name: 'Mission Impossible - Rogue Nation', year: 2015 },
        { origin: 'downloaded', name: 'Mission Impossible - Fallout', year: 2018 },
    ] },
    { type: 'movieFranchise', name: 'MonsterVerse', order: 'series', movies: [
        { origin: 'downloaded', name: 'Godzilla', year: 2014, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Kong - Skull Island', year: 2017, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Godzilla - King Of The Monsters', year: 2019, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Godzilla vs Kong', year: 2021, attributes: ['4K'] },
    ] },
    { type: 'movieFranchise', name: 'Night at the Museum', order: 'series', movies: [
        { origin: 'downloaded', name: 'Night at the Museum', year: 2006 },
        { origin: 'downloaded', name: 'Night at the Museum - Battle of the Smithsonian', year: 2009 },
        { origin: 'downloaded', name: 'Night at the Museum - Secret of the Tomb', year: 2014, attributes: ['4K'] },
    ] },
    { type: 'movieFranchise', name: 'Ocean\'s Heists', order: 'series', movies: [
        { origin: 'downloaded|purchased', name: 'Ocean\'s Eleven', year: 2001 },
        { origin: 'downloaded', name: 'Ocean\'s Twelve', year: 2004 },
        { origin: 'downloaded', name: 'Ocean\'s Thirteen', year: 2007 },
        { origin: 'downloaded|purchased', name: 'Ocean\'s Eight', year: 2018 },
    ] },
    { type: 'movieFranchise', name: 'Pirates of the Caribbean', order: 'series', movies: [
        { origin: 'downloaded', name: 'Curse of the Black Pearl', year: 2003 },
        { origin: 'downloaded', name: 'Dead Man\'s Chest', year: 2006 },
        { origin: 'downloaded', name: 'At Worlds End', year: 2007 },
        { origin: 'downloaded', name: 'On Stranger Tides', year: 2011 },
        { origin: 'downloaded', name: 'Dead Men Tell No Tales', year: 2017 },
    ] },
    { type: 'movieFranchise', name: 'Planet of the Apes', order: 'series', movies: [
        { origin: 'downloaded', name: 'Rise of the Planet of the Apes', year: 2011 },
        { origin: 'downloaded', name: 'Dawn of the Planet of the Apes', year: 2014 },
        { origin: 'downloaded', name: 'War for the Planet of the Apes', year: 2017 },
    ] },
    { type: 'movieFranchise', name: 'Saw', order: 'series', movies: [
        { origin: 'downloaded', name: 'Saw I', year: 2004 },
        { origin: 'downloaded', name: 'Saw II', year: 2005 },
        { origin: 'downloaded', name: 'Saw III', year: 2006 },
        { origin: 'downloaded', name: 'Saw IV', year: 2007 },
        { origin: 'downloaded', name: 'Saw V', year: 2008 },
        { origin: 'downloaded', name: 'Saw VI', year: 2009 },
        { origin: 'downloaded', name: 'Saw VII - The Final Chapter', year: 2010 },
        { origin: 'downloaded', name: 'Jigsaw', year: 2017 },
        { origin: 'downloaded', name: 'Spiral - From the Book of Saw', year: 2021 },
    ] },
    { type: 'movieFranchise', name: 'Scary Movie', order: 'series', movies: [
        { origin: 'downloaded', name: 'Scary Movie 1', year: 2000 },
        { origin: 'downloaded', name: 'Scary Movie 2', year: 2001 },
        { origin: 'downloaded', name: 'Scary Movie 3', year: 2003 },
        { origin: 'downloaded', name: 'Scary Movie 4', year: 2006 },
        { origin: 'downloaded', name: 'Scary Movie 5', year: 2013 },
    ] },
    { type: 'movieFranchise', name: 'Shrek', order: 'series', movies: [
        { origin: 'downloaded', name: 'Shrek', year: 2001 },
        { origin: 'downloaded', name: 'Shrek 2', year: 2004 },
        { origin: 'downloaded', name: 'Shrek the Third', year: 2007 },
        { origin: 'downloaded', name: 'Shrek Forever After', year: 2010 },
        { origin: 'downloaded', name: 'Puss in Boots', year: 2011 },
    ] },
    { type: 'movieFranchise', name: 'Spiderman', order: 'series', movies: [
        { origin: 'downloaded', name: 'Spiderman', year: 2002 },
        { origin: 'downloaded', name: 'Spiderman 2', year: 2004 },
        { origin: 'downloaded', name: 'Spiderman 3', year: 2007 },
    ] },
    { type: 'movieFranchise', name: 'Spy Kids', order: 'series', movies: [
        { origin: 'downloaded', name: 'Spy Kids', year: 2001 },
        { origin: 'downloaded', name: 'Spy Kids 2 - Island of Lost Dreams', year: 2002 },
        { origin: 'downloaded', name: 'Spy Kids 3 - Game Over', year: 2003 },
    ] },
    { type: 'movieFranchise', name: 'Stand Up', order: 'series', movies: [
        { origin: 'downloaded', name: 'Gabriel Iglesias - Im Not Fat Im Fluffy', year: 2009 },
        { origin: 'downloaded', name: 'Gary Gulman - The Great Depresh', year: 2019 },
        { origin: 'downloaded', name: 'The Fluffy Movie', year: 2014 },
        { origin: 'downloaded', name: 'Tom Segura - Ball Hog', year: 2020 },
    ] },
    { type: 'movieFranchise', name: 'The Amazing Spider Man', order: 'series', movies: [
        { origin: 'downloaded', name: 'The Amazing Spiderman', year: 2012, attributes: ['4K'] },
        { origin: 'downloaded', name: 'The Amazing Spiderman 2', year: 2014, attributes: ['4K'] },
    ] },
    { type: 'movieFranchise', name: 'The Conjuring', order: 'series', movies: [
        { origin: 'downloaded', name: 'The Conjuring', year: 2013 },
        { origin: 'downloaded', name: 'The Conjuring 2', year: 2016 },
        { origin: 'downloaded', name: 'The Conjuring - The Devil Made Me Do It', year: 2021 },
    ] },
    { type: 'movieFranchise', name: 'The Dollanganger Series', order: 'series', movies: [
        { origin: 'downloaded', name: 'Flowers in the Attic', year: 2014 },
        { origin: 'downloaded', name: 'Petals On The Wind', year: 2014 },
        { origin: 'downloaded', name: 'If There Be Thorns', year: 2015 },
        { origin: 'downloaded', name: 'Seeds Of Yesterday', year: 2015 },
    ] },
    { type: 'movieFranchise', name: 'The Hangover', order: 'series', movies: [
        { origin: 'downloaded', name: 'The Hangover', year: 2009 },
        { origin: 'downloaded', name: 'The Hangover Part II', year: 2011 },
        { origin: 'downloaded', name: 'The Hangover Part III', year: 2013 },
    ] },
    { type: 'movieFranchise', name: 'The Hunger Games', order: 'series', movies: [
        { origin: 'downloaded|purchased', name: 'The Hunger Games', year: 2012 },
        { origin: 'downloaded|purchased', name: 'The Hunger Games - Catching Fire', year: 2013 },
        { origin: 'downloaded|purchased', name: 'The Hunger Games - Mockingjay Part 1', year: 2014 },
        { origin: 'downloaded|purchased', name: 'The Hunger Games - Mockingjay Part 2', year: 2015 },
    ] },
    { type: 'movieFranchise', name: 'The Karate Kid', order: 'series', movies: [
        { origin: 'downloaded', name: 'The Karate Kid', year: 1984 },
        { origin: 'downloaded', name: 'The Karate Kid Part II', year: 1986 },
        { origin: 'downloaded', name: 'The Karate Kid Part III', year: 1989 },
        { origin: 'downloaded', name: 'The Next Karate Kid', year: 1994 },
        { origin: 'downloaded', name: 'The Karate Kid', year: 2010 },
    ] },
    { type: 'movieFranchise', name: 'The Lord of the Rings', order: 'series', movies: [
        { origin: 'downloaded', name: 'The Fellowship of the Ring', year: 2001 },
        { origin: 'downloaded', name: 'The Two Towers', year: 2002 },
        { origin: 'downloaded', name: 'The Return of the King', year: 2003 },
    ] },
    { type: 'movieFranchise', name: 'The Matrix', order: 'series', movies: [
        { origin: 'downloaded|purchased', name: 'The Matrix', year: 1999, attributes: ['4K'] },
        { origin: 'downloaded|purchased', name: 'The Matrix Reloaded', year: 2003 },
        { origin: 'downloaded|purchased', name: 'The Matrix Revolutions', year: 2004 },
    ] },
    { type: 'movieFranchise', name: 'The Maze Runner', order: 'series', movies: [
        { origin: 'downloaded', name: 'The Maze Runner', year: 2014 },
        { origin: 'downloaded', name: 'The Scortch Trials', year: 2015 },
        { origin: 'downloaded', name: 'The Death Cure', year: 2018 },
    ] },
    { type: 'movieFranchise', name: 'The Purge', order: 'series', movies: [
        { origin: 'downloaded', name: 'The Purge', year: 2013 },
        { origin: 'downloaded', name: 'The Purge Anarchy', year: 2014 },
        { origin: 'downloaded', name: 'The Purge Election Year', year: 2016 },
        { origin: 'downloaded', name: 'The First Purge', year: 2018 },
    ] },
    { type: 'movieFranchise', name: 'The Santa Clause', order: 'series', movies: [
        { origin: 'downloaded', name: 'The Santa Clause', year: 1994 },
        { origin: 'downloaded', name: 'The Santa Clause 2', year: 2002 },
        { origin: 'downloaded', name: 'The Santa Clause 3 - The Escape Clause', year: 2006 },
    ] },
    { type: 'movieFranchise', name: 'The Twilight Saga', order: 'series', movies: [
        { origin: 'downloaded', name: 'Twilight', year: 2008 },
        { origin: 'downloaded', name: 'New Moon', year: 2009 },
        { origin: 'downloaded', name: 'Eclipse', year: 2010 },
        { origin: 'downloaded', name: 'Breaking Dawn - Part 1', year: 2011 },
        { origin: 'downloaded', name: 'Breaking Dawn - Part 2', year: 2012 },
    ] },
    { type: 'movieFranchise', name: 'Transformers', order: 'series', movies: [
        { origin: 'downloaded', name: 'Transformers', year: 2007 },
        { origin: 'downloaded', name: 'Transformers - Revenge of the Fallen', year: 2009, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Transformers - Dark of the Moon', year: 2011 },
        { origin: 'downloaded', name: 'Transformers - Age of Extinction', year: 2014 },
        { origin: 'downloaded', name: 'Transformers - The Last Knight', year: 2017, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Bumblebee', year: 2018, attributes: ['4K'] },
    ] },
    { type: 'movieFranchise', name: 'X-Men', order: 'series', movies: [
        { origin: 'downloaded', name: 'X-Men', year: 2000 },
        { origin: 'downloaded', name: 'X2', year: 2003 },
        { origin: 'downloaded', name: 'X-Men The Last Stand', year: 2006 },
        { origin: 'downloaded', name: 'X-Men Origins - Wolverine', year: 2009, attributes: ['4K'] },
        { origin: 'downloaded', name: 'X-Men First Class', year: 2011, attributes: ['4K'] },
        { origin: 'downloaded', name: 'The Wolverine', year: 2013, attributes: ['4K'] },
        { origin: 'downloaded', name: 'X-Men - Days of Future Past', year: 2014 },
        { origin: 'downloaded', name: 'X-Men - Days of Future Past - The Rogue Cut', year: 2014 },
        { origin: 'downloaded', name: 'Deadpool', year: 2016 },
        { origin: 'downloaded', name: 'X-Men Apocalypse', year: 2016, attributes: ['4K'] },
        { origin: 'downloaded|purchased', name: 'Logan', year: 2017, attributes: ['4K'] },
        { origin: 'downloaded', name: 'Deadpool 2', year: 2018 },
        { origin: 'downloaded|purchased', name: 'Once Upon a Deadpool', year: 2018 },
        { origin: 'downloaded', name: 'Dark Phoenix', year: 2019, attributes: ['4K'] },
        { origin: 'downloaded', name: 'The New Mutants', year: 2020, attributes: ['4K'] },
    ] },

    // Movies
    { type: 'movie', origin: 'downloaded', name: '12 Dates of Christmas', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: '12 Years a Slave', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: '127 Hours', year: 2010 },
    { type: 'movie', origin: 'downloaded', name: '13 Going On 30', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: '17 Again', year: 2009 },
    { type: 'movie', origin: 'downloaded', name: '1917', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: '2 Guns', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: '2012', year: 2009 },
    { type: 'movie', origin: 'downloaded', name: '2067', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: '21 Jump Street', year: 2012 },
    { type: 'movie', origin: 'downloaded', name: '22 Jump Street', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: '300', year: 2006 },
    { type: 'movie', origin: 'downloaded', name: '42', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: '50 First Dates', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: '500 Days of Summer', year: 2009 },
    { type: 'movie', origin: 'downloaded', name: '6 Underground', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: '8 Mile', year: 2002 },
    { type: 'movie', origin: 'downloaded', name: 'A Beautiful Mind', year: 2001 },
    { type: 'movie', origin: 'downloaded', name: 'A Cure for Wellness', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'A Million Ways to Die in the West', year: 2014 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'A Quiet Place', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'A Quiet Place Part II', year: 2020, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'A Star Is Born', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'A Time to Kill', year: 1996 },
    { type: 'movie', origin: 'downloaded', name: 'Abominable', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'About Time', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'Ad Astra', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Alita - Battle Angel', year: 2019 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Alpha', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'American Hustle', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'American Made', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'American Sniper', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'American Ultra', year: 2015 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Arrival', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Assassins Creed', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Atomic Blonde', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'Ava', year: 2020 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Avatar', year: 2009, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Baby Driver', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'Bad Teacher', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Battleship', year: 2012 },
    { type: 'movie', origin: 'downloaded', name: 'Baywatch', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'Bill and Ted Face The Music', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Black Swan', year: 2010 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Blockers', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Bloodshot', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'BMS - The Rise of Thadland', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Bohemian Rhapsody', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Borat - Cultural Learnings of America for Make Benefit Glorious Nation of Kazakhstan', year: 2006 },
    { type: 'movie', origin: 'downloaded', name: 'Borat Subsequent Moviefilm', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Boss Level', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Bottle Shock', year: 2008 },
    { type: 'movie', origin: 'downloaded', name: 'Brick Mansions', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Brightburn', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Buffy The Vampire Slayer', year: 1992 },
    { type: 'movie', origin: 'downloaded', name: 'Cashback', year: 2006 },
    { type: 'movie', origin: 'downloaded', name: 'Catch Me If You Can', year: 2002 },
    { type: 'movie', origin: 'downloaded', name: 'Catwoman', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'Chaos Walking', year: 2021 },
    { type: 'movie', origin: 'downloaded', name: 'Charlies Angels', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Children of Men', year: 2006 },
    { type: 'movie', origin: 'downloaded', name: 'Chronicle', year: 2012 },
    { type: 'movie', origin: 'downloaded', name: 'Code 8', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Code 8', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Come Play', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Concussion', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Contact', year: 1997 },
    { type: 'movie', origin: 'downloaded', name: 'Contagion', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Coraline', year: 2009 },
    { type: 'movie', origin: 'downloaded', name: 'Coyote Ugly', year: 2000 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Crazy Rich Asians', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Crazy Stupid Love', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Daredevil', year: 2003 },
    { type: 'movie', origin: 'downloaded', name: 'Date Night', year: 2010 },
    { type: 'movie', origin: 'downloaded', name: 'Dead Poets Society', year: 1989 },
    { type: 'movie', origin: 'downloaded', name: 'Deepwater Horizon', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Deja Vu', year: 2006 },
    { type: 'movie', origin: 'downloaded', name: 'Disobedience', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'District 9', year: 2009 },
    { type: 'movie', origin: 'downloaded', name: 'Django Unchained', year: 2012 },
    { type: 'movie', origin: 'downloaded', name: 'Dodgeball', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'Dolittle', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Dont Breathe', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Downsizing', year: 2017 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Drumline', year: 2002 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Drumline - A New Beat', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Dude, Where\'s My Car', year: 2000 },
    { type: 'movie', origin: 'downloaded', name: 'Dunkirk', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'Easy A', year: 2010 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Edge of Tomorrow', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Eighth Grade', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'El Camino - A Breaking Bad Movie', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Elektra', year: 2005 },
    { type: 'movie', origin: 'downloaded', name: 'Elf', year: 2003 },
    { type: 'movie', origin: 'downloaded', name: 'Elysium', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'Ender\'s Game', year: 2013, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Endless Love', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Enola Holmes', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Ex Machina', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Fantastic 4 - Rise of the Silver Surfer', year: 2007 },
    { type: 'movie', origin: 'downloaded', name: 'Fantastic Four', year: 2005 },
    { type: 'movie', origin: 'downloaded', name: 'Fantastic Four', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Fantasy Island', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Fatale', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Ferris Bueller\'s Day Off', year: 1986 },
    { type: 'movie', origin: 'downloaded', name: 'Fight Club', year: 1999 },
    { type: 'movie', origin: 'downloaded', name: 'Fighting With My Family', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'First Man', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Flatliners', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'Flight', year: 2012 },
    { type: 'movie', origin: 'downloaded', name: 'Focus', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Ford v Ferrari', year: 2019, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Forgetting Sarah Marshall', year: 2008 },
    { type: 'movie', origin: 'downloaded', name: 'Forrest Gump', year: 1994 },
    { type: 'movie', origin: 'downloaded', name: 'Freaky', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Freaky Friday', year: 2003 },
    { type: 'movie', origin: 'downloaded', name: 'Free Guy', year: 2021, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Friends The Reunion', year: 2021 },
    { type: 'movie', origin: 'downloaded', name: 'Friends With Benefits', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Fun with Dick and Jane', year: 2005 },
    { type: 'movie', origin: 'downloaded', name: 'Gamer', year: 2009 },
    { type: 'movie', origin: 'downloaded', name: 'Gattaca', year: 1997 },
    { type: 'movie', origin: 'downloaded', name: 'Gemini Man', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Get Hard', year: 2015 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Get Out', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'Ghost In The Shell', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'Ghost Rider', year: 2007 },
    { type: 'movie', origin: 'downloaded', name: 'Ghost Rider 2 - Spirit of Vengeance', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Girl Interrupted', year: 1999 },
    { type: 'movie', origin: 'downloaded', name: 'Gladiator', year: 2000 },
    { type: 'movie', origin: 'downloaded', name: 'Gone Girl', year: 2014 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Good Will Hunting', year: 1997 },
    { type: 'movie', origin: 'downloaded', name: 'Gravity', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'Gretel and Hansel', year: 2020 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Hacksaw Ridge', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Hall Pass', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Hamilton', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Hancock', year: 2008 },
    { type: 'movie', origin: 'downloaded', name: 'Hanna', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Happy Death Day', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'Happy Death Day 2U', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Hellboy', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'Hellboy II - The Golden Army', year: 2008 },
    { type: 'movie', origin: 'downloaded', name: 'Her', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'Hidden Figures', year: 2016 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Hitch', year: 2005 },
    { type: 'movie', origin: 'downloaded', name: 'Holidate', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Holmes and Watson', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Horrible Bosses', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Horrible Bosses 2', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'How It Ends', year: 2021 },
    { type: 'movie', origin: 'downloaded', name: 'How the Grinch Stole Christmas', year: 2000 },
    { type: 'movie', origin: 'downloaded', name: 'How to Lose a Guy in 10 Days', year: 2003 },
    { type: 'movie', origin: 'downloaded', name: 'How To Lose Friends & Alienate People', year: 2008 },
    { type: 'movie', origin: 'downloaded', name: 'Hubie Halloween', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Hustlers', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'I Am Greta', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'I Am Legend', year: 2007 },
    { type: 'movie', origin: 'downloaded', name: 'I Am Mother', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'I Am Number Four', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'I Love You, Beth Cooper', year: 2010 },
    { type: 'movie', origin: 'downloaded', name: 'I Love You, Man', year: 2009 },
    { type: 'movie', origin: 'downloaded', name: 'I Now Pronounce You Chuck and Larry', year: 2007 },
    { type: 'movie', origin: 'downloaded', name: 'I, Robot', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'Idiocracy', year: 2006 },
    { type: 'movie', origin: 'downloaded', name: 'In Time', year: 2011 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Inception', year: 2010 },
    { type: 'movie', origin: 'downloaded', name: 'Independence Day', year: 1996 },
    { type: 'movie', origin: 'downloaded', name: 'Ingrid Goes West', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'Insidious', year: 2010 },
    { type: 'movie', origin: 'downloaded', name: 'Instant Family', year: 2018 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Interstellar', year: 2014, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Isnt It Romantic', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'It Follows', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Jennifer\'s Body', year: 2009 },
    { type: 'movie', origin: 'downloaded', name: 'Jobs', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'Joe Versus The Volcano', year: 1990 },
    { type: 'movie', origin: 'downloaded', name: 'John Carter', year: 2012 },
    { type: 'movie', origin: 'downloaded', name: 'Joker', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Jumper', year: 2008 },
    { type: 'movie', origin: 'downloaded', name: 'Juno', year: 2007 },
    { type: 'movie', origin: 'downloaded', name: 'Jupiter Ascending', year: 2015 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Jurassic World', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Jurassic World - Fallen Kingdom', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Keanu', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Kick-Ass', year: 2010 },
    { type: 'movie', origin: 'downloaded', name: 'Kick-Ass 2', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'Kingsman - The Golden Circle', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'Kingsman - The Secret Service', year: 2014, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Knives Out', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Knocked Up', year: 2007 },
    { type: 'movie', origin: 'downloaded', name: 'Law Abiding Citizen', year: 2009 },
    { type: 'movie', origin: 'downloaded', name: 'Left Behind', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Lemony Snicket\'s A Series of Unfortunate Events', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'Life of Pi', year: 2012 },
    { type: 'movie', origin: 'downloaded', name: 'Limitless', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Lock Up', year: 1989 },
    { type: 'movie', origin: 'downloaded', name: 'Looper', year: 2012 },
    { type: 'movie', origin: 'downloaded', name: 'Love and Monsters', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Lucy', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Margin Call', year: 2011 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Marley & Me', year: 2008 },
    { type: 'movie', origin: 'downloaded', name: 'Masterminds', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Mean Girls', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'Mean Girls 2', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Men In Black - International', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Midsommar', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Mortal Engines', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Mortal Kombat', year: 2021, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Mr and Mrs Smith', year: 2005 },
    { type: 'movie', origin: 'downloaded', name: 'Mud', year: 2012 },
    { type: 'movie', origin: 'downloaded', name: 'Napoleon Dynamite', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'National Treasure', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'National Treasure - Book of Secrets', year: 2007 },
    { type: 'movie', origin: 'downloaded', name: 'Neighbors', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Neighbors 2 - Sorority Rising', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Nerve', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Network', year: 1976 },
    { type: 'movie', origin: 'downloaded', name: 'New Years Eve', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Nightcrawler', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'No Country for Old Men', year: 2007 },
    { type: 'movie', origin: 'downloaded', name: 'No Strings Attached', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Not Another Teen Movie', year: 2001 },
    { type: 'movie', origin: 'downloaded', name: 'Now You See Me', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'Now You See Me 2', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Oblivion', year: 2013 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Office Space', year: 1999 },
    { type: 'movie', origin: 'downloaded', name: 'Once Upon A Time - In Hollywood', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Out of Sight', year: 1998 },
    { type: 'movie', origin: 'downloaded', name: 'Pacific Rim', year: 2013, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Pacific Rim - Uprising', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Palm Springs', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Paper Towns', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Parasite', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Passengers', year: 2016 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Patriots Day', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Percy Jackson & the Olympians - The Lightning Thief', year: 2010 },
    { type: 'movie', origin: 'downloaded', name: 'Percy Jackson - Sea of Monsters', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'Pineapple Express', year: 2008 },
    { type: 'movie', origin: 'downloaded', name: 'Pixels', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Pokémon - Detective Pikachu', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Pokémon - Mewtwo Strikes Back', year: 1998 },
    { type: 'movie', origin: 'downloaded', name: 'Pokémon - Mewtwo Strikes Back - Evolution', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Power Rangers', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'Predestination', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Primer', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'Project Almanac', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Project Power', year: 2020, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Promising Young Woman', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Queen & Slim', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Rampage', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Ready or Not', year: 2019 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Ready Player One', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Real Steel', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Red Notice', year: 2021 },
    { type: 'movie', origin: 'downloaded', name: 'Red Riding Hood', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Red Sparrow', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Remember the Titans', year: 2000 },
    { type: 'movie', origin: 'downloaded', name: 'Reminiscence', year: 2021, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Revolutionary Road', year: 2008 },
    { type: 'movie', origin: 'downloaded', name: 'Ride Along', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Ride Along 2', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Robin Hood', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'RoboCop', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Rocketman', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Romeo And Juliet', year: 1996 },
    { type: 'movie', origin: 'downloaded', name: 'Romeo and Juliet', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'Room', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Safety Not Guaranteed', year: 2012 },
    { type: 'movie', origin: 'downloaded', name: 'Scoob', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Scooby-Doo', year: 2002 },
    { type: 'movie', origin: 'downloaded', name: 'Scooby-Doo 2 - Monsters Unleashed', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'Scott Pilgrim vs The World', year: 2010 },
    { type: 'movie', origin: 'downloaded', name: 'SelfLess', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Set It Up', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Seven', year: 1995 },
    { type: 'movie', origin: 'downloaded', name: 'Sex Tape', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Sherlock Holmes', year: 2009 },
    { type: 'movie', origin: 'downloaded', name: 'Sherlock Holmes - A Game of Shadows', year: 2011 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Silver Linings Playbook', year: 2012 },
    { type: 'movie', origin: 'downloaded', name: 'Sixth Sense', year: 1999 },
    { type: 'movie', origin: 'downloaded', name: 'Sky High', year: 2005 },
    { type: 'movie', origin: 'downloaded', name: 'Skylines', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Slumdog Millionaire', year: 2008 },
    { type: 'movie', origin: 'downloaded', name: 'Snowden', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Songbird', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Sonic The Hedgehog', year: 2020, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Sophie\'s Choice', year: 1982 },
    { type: 'movie', origin: 'downloaded', name: 'Source Code', year: 2011, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Space Jam', year: 1996 },
    { type: 'movie', origin: 'downloaded', name: 'Space Jam - A New Legacy', year: 2021, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Spaceballs', year: 1987 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Spider-Man - Into the Spider-Verse', year: 2018, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Spies In Disguise', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Split', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Spotlight', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Spring Breakers', year: 2012 },
    { type: 'movie', origin: 'downloaded', name: 'Stealth', year: 2005 },
    { type: 'movie', origin: 'downloaded', name: 'Steve Jobs', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Stranger Than Fiction', year: 2006 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Stronger', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'Sucker Punch', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Sucker Punch - Extended Cut', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'Sully', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Super Troopers', year: 2001 },
    { type: 'movie', origin: 'downloaded', name: 'Super Troopers 2', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Superbad', year: 2007 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Tag', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Talladega Nights - The Ballad of Ricky Bobby', year: 2006 },
    { type: 'movie', origin: 'downloaded', name: 'Taxi Driver', year: 1976 },
    { type: 'movie', origin: 'downloaded', name: 'Ted', year: 2012 },
    { type: 'movie', origin: 'downloaded', name: 'Ted 2', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Teenage Mutant Ninja Turtles', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Tell Me How I Die', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Tenet', year: 2020, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Thank You For Smoking', year: 2005 },
    { type: 'movie', origin: 'downloaded', name: 'That Awkward Moment', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'The 40 Year Old Virgin', year: 2005 },
    { type: 'movie', origin: 'downloaded', name: 'The 5th Wave', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'The A-Team', year: 2010 },
    { type: 'movie', origin: 'downloaded', name: 'The Adventures of Sharkboy and Lavagirl', year: 2005 },
    { type: 'movie', origin: 'downloaded', name: 'The Big Short', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'The Breakfast Club', year: 1985 },
    { type: 'movie', origin: 'downloaded', name: 'The Butterfly Effect', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'The Call of the Wild', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'The Change Up', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'The Change-Up', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'The Dark Tower', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'The Day After Tomorrow', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'The Day the Earth Stood Still', year: 2008 },
    { type: 'movie', origin: 'downloaded', name: 'The Duff', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'The Edge of Seventeen', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'The Florida Project', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'The Gentlemen', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'The Girl with the Dragon Tattoo', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'The Giver', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'The Golden Compass', year: 2007 },
    { type: 'movie', origin: 'downloaded', name: 'The Great Gatsby', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'The Greatest Showman', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'The Grinch', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'The Hitchhikers Guide to the Galaxy', year: 2005 },
    { type: 'movie', origin: 'downloaded', name: 'The Hitmans Bodyguard', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'The Host', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'The Hunt', year: 2020 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'The Imitation Game', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'The Informer', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'The Interview', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'The Invisible Man', year: 1933 },
    { type: 'movie', origin: 'downloaded', name: 'The Invisible Man', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'The Iron Giant', year: 1999 },
    { type: 'movie', origin: 'downloaded', name: 'The King Of Staten Island', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'The Last Airbender', year: 2010 },
    { type: 'movie', origin: 'downloaded', name: 'The Lazarus Effect', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'The League Of Extraordinary Gentlemen', year: 2003 },
    { type: 'movie', origin: 'downloaded', name: 'The Legend of Tarzan', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'The Map of Tiny Perfect Things', year: 2021 },
    { type: 'movie', origin: 'downloaded', name: 'The Martian', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'The Mechanic', year: 2011 },
    { type: 'movie', origin: 'downloaded', name: 'The Mechanic Resurrection', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'The Meg', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'The Mortal Instruments City of Bones', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'The Parent Trap', year: 1998 },
    { type: 'movie', origin: 'downloaded', name: 'The Polar Express', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'The Post', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'The Punisher', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'The Revenant', year: 2015, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'The Room', year: 2003 },
    { type: 'movie', origin: 'downloaded', name: 'The Secret Life of Walter Mitty', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'The Shawshank Redemption', year: 1994 },
    { type: 'movie', origin: 'downloaded', name: 'The Simpsons Movie', year: 2007 },
    { type: 'movie', origin: 'downloaded', name: 'The Social Network', year: 2010 },
    { type: 'movie', origin: 'downloaded', name: 'The Sorcerer\'s Apprentice', year: 2010 },
    { type: 'movie', origin: 'downloaded', name: 'The SpongeBob SquarePants Movie', year: 2004 },
    { type: 'movie', origin: 'downloaded', name: 'The Theory of Everything', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'The Time Travelers Wife', year: 2009 },
    { type: 'movie', origin: 'downloaded', name: 'The Tomorrow War', year: 2021 },
    { type: 'movie', origin: 'downloaded', name: 'The Trial of the Chicago 7', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'The Wolf of Wall Street', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'The Year Without a Santa Claus', year: 1974 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'This is the End', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'Those Who Wish Me Dead', year: 2021 },
    { type: 'movie', origin: 'downloaded', name: 'To All the Boys Always and Forever', year: 2021 },
    { type: 'movie', origin: 'downloaded', name: 'Tom and Jerry', year: 2021, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Tomb Raider', year: 2018, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Tomorrowland', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Total Recall', year: 2012 },
    { type: 'movie', origin: 'downloaded', name: 'Transcendence', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Tron Legacy', year: 2010, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Truth or Dare', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Twelve Monkeys', year: 1995 },
    { type: 'movie', origin: 'downloaded', name: 'Two Night Stand', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Unbreakable', year: 2000 },
    { type: 'movie', origin: 'downloaded', name: 'Under the Silver Lake', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Underwater', year: 2020 },
    { type: 'movie', origin: 'downloaded', name: 'Upgrade', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Vacation', year: 2015 },
    { type: 'movie', origin: 'downloaded', name: 'Valentines Day', year: 2010 },
    { type: 'movie', origin: 'downloaded', name: 'Valerian and the City of a Thousand Planets', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'Van Wilder 2 - The Rise Of Taj', year: 2006 },
    { type: 'movie', origin: 'downloaded', name: 'Venom', year: 2018, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Venom - Let There Be Carnage', year: 2021, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Veronica Mars', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Vivarium', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Voyagers', year: 2021 },
    { type: 'movie', origin: 'downloaded', name: 'Wanted', year: 2008 },
    { type: 'movie', origin: 'downloaded', name: 'War Dogs', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Watchmen', year: 2009, attributes: ['4K'] },
    { type: 'movie', origin: 'downloaded', name: 'Waynes World', year: 1992 },
    { type: 'movie', origin: 'downloaded', name: 'Waynes World 2', year: 1993 },
    { type: 'movie', origin: 'downloaded', name: 'We Are The Millers', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'Wedding Crashers - Uncorked Edition', year: 2005 },
    { type: 'movie', origin: 'downloaded', name: 'What Happened To Monday', year: 2017 },
    { type: 'movie', origin: 'downloaded', name: 'When We First Met', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Whiplash', year: 2014 },
    { type: 'movie', origin: 'downloaded', name: 'Whiskey Tango Foxtrot', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'White House Down', year: 2013 },
    { type: 'movie', origin: 'downloaded', name: 'Why Him', year: 2016 },
    { type: 'movie', origin: 'downloaded', name: 'Widows', year: 2018 },
    { type: 'movie', origin: 'downloaded', name: 'Winter\'s Bone', year: 2010 },
    { type: 'movie', origin: 'downloaded', name: 'You Don\'t Mess with the Zohan', year: 2008 },
    { type: 'movie', origin: 'downloaded', name: 'Zodiac', year: 2007 },
    { type: 'movie', origin: 'downloaded|purchased', name: 'Zombieland', year: 2009 },
    { type: 'movie', origin: 'downloaded', name: 'Zombieland - Double Tap', year: 2019 },
    { type: 'movie', origin: 'downloaded', name: 'Zoolander', year: 2001 },

    // TV Show Franchises
    { type: 'tvShowFranchise', name: 'Arrowverse', tvShows: [
        { origin: 'downloaded', name: 'Arrow', seasons: [
            { number: 1, years: '2012-13', episodes: '1-23', downloadStatus: 'complete' },
            { number: 2, years: '2013-14', episodes: '1-23', downloadStatus: 'complete' },
            { number: 3, years: '2014-15', episodes: '1-23', downloadStatus: 'complete' },
            { number: 4, years: '2015-16', episodes: '1-23', downloadStatus: 'complete' },
            { number: 5, years: '2016-17', episodes: '1-23', downloadStatus: 'complete' },
            { number: 6, years: '2017-18', episodes: '1-23', downloadStatus: 'complete' },
            { number: 7, years: '2018-19', episodes: '1-22', downloadStatus: 'complete' },
            { number: 8, years: '2019-20', episodes: '1-10', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Batwoman', seasons: [
            { number: 1, years: '2019-20', episodes: '1-20', downloadStatus: 'complete' },
            { number: 2, years: '2021', episodes: '1-18', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Black Lightning', seasons: [
            { number: 1, years: '2018', episodes: '1-13', downloadStatus: 'complete' },
            { number: 2, years: '2018-19', episodes: '1-16', downloadStatus: 'complete' },
            { number: 3, years: '2019-20', episodes: '1-16', downloadStatus: 'complete' },
            { number: 4, years: '2021', episodes: '1-13', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Legends of Tomorrow', seasons: [
            { number: 1, years: '2016', episodes: '1-16', downloadStatus: 'complete' },
            { number: 2, years: '2016-17', episodes: '1-17', downloadStatus: 'complete' },
            { number: 3, years: '2017-18', episodes: '1-18', downloadStatus: 'complete' },
            { number: 4, years: '2018-19', episodes: '1-16', downloadStatus: 'complete' },
            { number: 5, years: '2020', episodes: '1-14', downloadStatus: 'incomplete' },
            { number: 6, years: '2021', episodes: null, downloadStatus: 'incomplete' },
        ] },
        { origin: 'downloaded', name: 'Supergirl', seasons: [
            { number: 1, years: '2015-16', episodes: '1-20', downloadStatus: 'complete' },
            { number: 2, years: '2016-17', episodes: '1-22', downloadStatus: 'complete' },
            { number: 3, years: '2017-18', episodes: '1-23', downloadStatus: 'complete' },
            { number: 4, years: '2018-19', episodes: '1-22', downloadStatus: 'complete' },
            { number: 5, years: '2019-20', episodes: '1-19', downloadStatus: 'complete' },
            { number: 6, years: '2021', episodes: null, downloadStatus: 'incomplete' },
        ] },
        { origin: 'downloaded', name: 'The Flash', seasons: [
            { number: 1, years: '2014-15', episodes: '1-23', downloadStatus: 'complete' },
            { number: 2, years: '2015-16', episodes: '1-23', downloadStatus: 'complete' },
            { number: 3, years: '2016-17', episodes: '1-23', downloadStatus: 'complete' },
            { number: 4, years: '2017-18', episodes: '1-23', downloadStatus: 'complete' },
            { number: 5, years: '2018-19', episodes: '1-22', downloadStatus: 'complete' },
            { number: 6, years: '2019-20', episodes: '1-19', downloadStatus: 'complete' },
            { number: 7, years: '2021', episodes: '1-18', downloadStatus: 'complete' },
            { number: 8, years: '2021', episodes: null, downloadStatus: 'incomplete' },
        ] },
    ] },
    { type: 'tvShowFranchise', name: 'Avatar', tvShows: [
        { origin: 'downloaded', name: 'The Last Airbender', seasons: [
            { number: 1, name: 'Book One: Water', years: '2005', episodes: '1-20', downloadStatus: 'complete' },
            { number: 2, name: 'Book Two: Earth', years: '2006', episodes: '1-20', downloadStatus: 'complete' },
            { number: 3, name: 'Book Three: Fire', years: '2007-08', episodes: '1-21', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'The Legend of Korra', seasons: [
            { number: 1, name: 'Book One: Air', years: '2012', episodes: '1-12', downloadStatus: 'complete' },
            { number: 2, name: 'Book Two: Spirits', years: '2013', episodes: '1-14', downloadStatus: 'complete' },
            { number: 3, name: 'Book Three: Change', years: '2014', episodes: '1-13', downloadStatus: 'complete' },
            { number: 4, name: 'Book Four: Balance', years: '2014', episodes: '1-13', downloadStatus: 'complete' },
        ] },
    ] },
    { type: 'tvShowFranchise', name: 'H2O', tvShows: [
        { origin: 'downloaded', name: 'H2O - Just Add Water', seasons: [
            { number: 1, years: '2006', episodes: '1-26', downloadStatus: 'complete' },
            { number: 2, years: '2007-08', episodes: '1-26', downloadStatus: 'complete' },
            { number: 3, years: '2009-10', episodes: '1-26', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Mako Mermaids', seasons: [
            { number: 1, years: '2013-14', episodes: '1-26', downloadStatus: 'complete' },
            { number: 2, years: '2015', episodes: '1-26', downloadStatus: 'complete' },
            { number: 3, years: '2016', episodes: '1-16', downloadStatus: 'complete' },
        ] },
    ] },
    { type: 'tvShowFranchise', name: 'Marvel Cinematic Universe', tvShows: [
        { origin: 'downloaded', name: 'Agent Carter', seasons: [
            { number: 1, years: '2015', episodes: '1-8', downloadStatus: 'complete' },
            { number: 2, years: '2016', episodes: '1-10', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Agents of S.H.I.E.L.D.', seasons: [
            { number: 1, years: '2013-14', episodes: '1-22', downloadStatus: 'complete' },
            { number: 2, years: '2014-15', episodes: '1-22', downloadStatus: 'complete' },
            { number: 3, years: '2015-16', episodes: '1-22', downloadStatus: 'complete' },
            { number: 4, years: '2016-17', episodes: '1-22', downloadStatus: 'complete' },
            { number: 5, years: '2017-18', episodes: '1-22', downloadStatus: 'complete' },
            { number: 6, years: '2019', episodes: '1-13', downloadStatus: 'complete' },
            { number: 7, years: '2020', episodes: '1-13', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Cloak and Dagger', seasons: [
            { number: 1, years: '2018', episodes: '1-10', downloadStatus: 'complete' },
            { number: 2, years: '2019', episodes: '1-10', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Daredevil', seasons: [
            { number: 1, years: '2015', episodes: '1-13', downloadStatus: 'complete' },
            { number: 2, years: '2016', episodes: '1-13', downloadStatus: 'complete' },
            { number: 3, years: '2018', episodes: '1-13', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Hawkeye', seasons: [
            { number: 1, years: '2021', episodes: '1-4', downloadStatus: 'incomplete' },
        ] },
        { origin: 'downloaded', name: 'Helstrom', seasons: [
            { number: 1, years: '2020', episodes: '1-10', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Inhumans', seasons: [
            { number: 1, years: '2017', episodes: '1-8', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Iron Fist', seasons: [
            { number: 1, years: '2017', episodes: '1-13', downloadStatus: 'complete' },
            { number: 2, years: '2018', episodes: '1-10', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Jessica Jones', seasons: [
            { number: 1, years: '2015', episodes: '1-13', downloadStatus: 'complete' },
            { number: 2, years: '2018', episodes: '1-13', downloadStatus: 'complete' },
            { number: 3, years: '2019', episodes: '1-13', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Loki', seasons: [
            { number: 1, years: '2021', episodes: '1-6', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Luke Cage', seasons: [
            { number: 1, years: '2016', episodes: '1-13', downloadStatus: 'complete' },
            { number: 2, years: '2018', episodes: '1-13', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Marvel Studios - Legends', seasons: [
            { number: 1, years: '2021', episodes: '1-14', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Runaways', seasons: [
            { number: 1, years: '2017-18', episodes: '1-10', downloadStatus: 'complete' },
            { number: 2, years: '2018', episodes: '1-13', downloadStatus: 'complete' },
            { number: 3, years: '2019', episodes: '1-10', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'The Defenders', seasons: [
            { number: 1, years: '2017', episodes: '1-8', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'The Falcon and the Winter Soldier', seasons: [
            { number: 1, years: '2021', episodes: '1-6', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'The Punisher', seasons: [
            { number: 1, years: '2017', episodes: '1-13', downloadStatus: 'complete' },
            { number: 2, years: '2019', episodes: '1-13', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'WandaVision', seasons: [
            { number: 1, years: '2021', episodes: '1-9', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'What If...', seasons: [
            { number: 1, years: '2021', episodes: '1-9', downloadStatus: 'complete' },
        ] },
    ] },
    { type: 'tvShowFranchise', name: 'Nickelodeon', tvShows: [
        { origin: 'downloaded', name: 'Danny Phantom', seasons: [
            { number: 1, years: '2004-05', episodes: '1-20', downloadStatus: 'complete' },
            { number: 2, years: '2005-06', episodes: '1-20', downloadStatus: 'complete' },
            { number: 3, years: '2006-07', episodes: '1-13', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Drake and Josh', seasons: [
            { number: 1, years: '2004', episodes: '1-6', downloadStatus: 'complete' },
            { number: 2, years: '2004', episodes: '1-14', downloadStatus: 'complete' },
            { number: 3, years: '2005-06', episodes: '1-17', downloadStatus: 'complete' },
            { number: 4, years: '2006-07', episodes: '1-19', downloadStatus: 'complete' },
            { number: 5, name: 'TV Movies', years: '2006-08', episodes: '1-2', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'iCarly', seasons: [
            { number: 1, years: '2007-08', episodes: '1-25', downloadStatus: 'complete' },
            { number: 2, years: '2008-09', episodes: '1-21', downloadStatus: 'complete' },
            { number: 3, years: '2009-10', episodes: '1-18', downloadStatus: 'complete' },
            { number: 4, years: '2010-11', episodes: '1-10', downloadStatus: 'complete' },
            { number: 5, years: '2011-12', episodes: '1-10', downloadStatus: 'complete' },
            { number: 6, years: '2012', episodes: '1-13', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'My Life as a Teenage Robot', seasons: [
            { number: 1, years: '2003-04', episodes: '1-13', downloadStatus: 'complete' },
            { number: 2, years: '2004-05', episodes: '1-13', downloadStatus: 'complete' },
            { number: 3, years: '2008-09', episodes: '1-13', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Sam and Cat', seasons: [
            { number: 1, years: '2013-14', episodes: '1-36', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Spongebob Squarepants', seasons: [
            { number: 1, years: '1999-01', episodes: '1-20', downloadStatus: 'complete' },
            { number: 2, years: '2000-03', episodes: null, downloadStatus: 'incomplete' },
            { number: 3, years: '2001-04', episodes: null, downloadStatus: 'incomplete' },
            { number: 4, years: '2005-07', episodes: null, downloadStatus: 'incomplete' },
            { number: 5, years: '2007-09', episodes: null, downloadStatus: 'incomplete' },
            { number: 6, years: '2008-10', episodes: null, downloadStatus: 'incomplete' },
            { number: 7, years: '2009-11', episodes: null, downloadStatus: 'incomplete' },
            { number: 8, years: '2011-12', episodes: null, downloadStatus: 'incomplete' },
            { number: 9, years: '2012-17', episodes: null, downloadStatus: 'incomplete' },
            { number: 10, years: '2016-17', episodes: null, downloadStatus: 'incomplete' },
            { number: 11, years: '2017-18', episodes: null, downloadStatus: 'incomplete' },
            { number: 12, years: '2018-21', episodes: null, downloadStatus: 'incomplete' },
            { number: 13, years: '2020-21', episodes: null, downloadStatus: 'incomplete' },
        ] },
        { origin: 'downloaded', name: 'Victorious', seasons: [
            { number: 1, years: '2010-11', episodes: '1-19', downloadStatus: 'complete' },
            { number: 2, years: '2011', episodes: '1-13', downloadStatus: 'complete' },
            { number: 3, years: '2012', episodes: '1-12', downloadStatus: 'complete' },
            { number: 4, years: '2012-13', episodes: '1-13', downloadStatus: 'complete' },
        ] },
        { origin: 'downloaded', name: 'Zoey 101', seasons: [
            { number: 1, years: '2005', episodes: '1-13', downloadStatus: 'complete' },
            { number: 2, years: '2005-06', episodes: '1-12', downloadStatus: 'complete' },
            { number: 3, years: '2006-08', episodes: '1-23', downloadStatus: 'complete' },
            { number: 4, years: '2008', episodes: '1-13', downloadStatus: 'complete' },
        ] },
    ] },

    // TV Shows
    { type: 'tvShow', origin: 'downloaded', name: '13 Reasons Why', seasons: [
        { number: 1, years: '2017', episodes: '1-13', downloadStatus: 'complete' },
        { number: 2, years: '2018', episodes: '1-13', downloadStatus: 'complete' },
        { number: 3, years: '2019', episodes: '1-13', downloadStatus: 'complete' },
        { number: 4, years: '2020', episodes: '1-10', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: '2 Broke Girls', seasons: [
        { number: 1, years: '2011-12', episodes: '1-24', downloadStatus: 'complete' },
        { number: 2, years: '2012-13', episodes: '1-24', downloadStatus: 'complete' },
        { number: 3, years: '2013-14', episodes: '1-24', downloadStatus: 'complete' },
        { number: 4, years: '2014-15', episodes: '1-22', downloadStatus: 'complete' },
        { number: 5, years: '2015-16', episodes: '1-22', downloadStatus: 'complete' },
        { number: 6, years: '2016-17', episodes: '1-22', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'A Series of Unfortunate Events', seasons: [
        { number: 1, years: '2017', episodes: '1-8', downloadStatus: 'complete' },
        { number: 2, years: '2018', episodes: '1-10', downloadStatus: 'complete' },
        { number: 3, years: '2019', episodes: '1-7', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Almost Human', seasons: [
        { number: 1, years: '2013-14', episodes: '1-13', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Alphas', seasons: [
        { number: 1, years: '2011', episodes: '1-11', downloadStatus: 'complete' },
        { number: 2, years: '2012', episodes: '1-13', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Atlanta', seasons: [
        { number: 1, years: '2016', episodes: '1-10', downloadStatus: 'complete' },
        { number: 2, years: '2018', episodes: '1-11', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Awake', seasons: [
        { number: 1, years: '2012', episodes: '1-13', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Black Mirror', seasons: [
        { number: 1, years: '2011', episodes: '1-3', downloadStatus: 'complete' },
        { number: 2, years: '2013', episodes: '1-4', downloadStatus: 'complete' },
        { number: 3, years: '2016', episodes: '1-6', downloadStatus: 'complete' },
        { number: 4, years: '2017', episodes: '1-6', downloadStatus: 'complete' },
        { number: 5, years: '2019', episodes: '1-3', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Blue Mountain State', seasons: [
        { number: 1, years: '2010', episodes: '1-13', downloadStatus: 'complete' },
        { number: 2, years: '2010-11', episodes: '1-13', downloadStatus: 'complete' },
        { number: 3, years: '2011', episodes: '1-13', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Breaking Bad', seasons: [
        { number: 1, years: '2008', episodes: '1-7', downloadStatus: 'complete' },
        { number: 2, years: '2009', episodes: '1-13', downloadStatus: 'complete' },
        { number: 3, years: '2010', episodes: '1-13', downloadStatus: 'complete' },
        { number: 4, years: '2011', episodes: '1-13', downloadStatus: 'complete' },
        { number: 5, years: '2012', episodes: '1-16', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Brooklyn Nine-Nine', seasons: [
        { number: 1, years: '2013-14', episodes: '1-22', downloadStatus: 'complete' },
        { number: 2, years: '2014-15', episodes: '1-23', downloadStatus: 'complete' },
        { number: 3, years: '2015-16', episodes: '1-23', downloadStatus: 'complete' },
        { number: 4, years: '2016-17', episodes: '1-22', downloadStatus: 'complete' },
        { number: 5, years: '2017-18', episodes: '1-22', downloadStatus: 'complete' },
        { number: 6, years: '2019', episodes: '1-18', downloadStatus: 'complete' },
        { number: 7, years: '2020', episodes: '1-13', downloadStatus: 'complete' },
        { number: 8, years: '2021', episodes: '1-10', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Buffy The Vampire Slayer', seasons: [
        { number: 1, years: '1997', episodes: '1-12', downloadStatus: 'complete' },
        { number: 2, years: '1997-98', episodes: '1-22', downloadStatus: 'complete' },
        { number: 3, years: '1998-99', episodes: '1-22', downloadStatus: 'complete' },
        { number: 4, years: '1999-00', episodes: '1-22', downloadStatus: 'complete' },
        { number: 5, years: '2000-01', episodes: '1-22', downloadStatus: 'complete' },
        { number: 6, years: '2001-02', episodes: '1-22', downloadStatus: 'complete' },
        { number: 7, years: '2002-03', episodes: '1-22', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Chilling Adventures of Sabrina', seasons: [
        { number: 1, years: '2018', episodes: '1-11', downloadStatus: 'complete' },
        { number: 2, years: '2019', episodes: '1-9', downloadStatus: 'complete' },
        { number: 3, years: '2020', episodes: '1-8', downloadStatus: 'complete' },
        { number: 4, years: '2020', episodes: '1-8', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Community', seasons: [
        { number: 1, years: '2009-10', episodes: '1-25', downloadStatus: 'complete' },
        { number: 2, years: '2010-11', episodes: '1-24', downloadStatus: 'complete' },
        { number: 3, years: '2011-12', episodes: '1-22', downloadStatus: 'complete' },
        { number: 4, years: '2013', episodes: '1-13', downloadStatus: 'complete' },
        { number: 5, years: '2014', episodes: '1-13', downloadStatus: 'complete' },
        { number: 6, years: '2015', episodes: '1-13', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Faking It', seasons: [
        { number: 1, years: '2014', episodes: '1-8', downloadStatus: 'complete' },
        { number: 2, years: '2014-15', episodes: '1-20', downloadStatus: 'complete' },
        { number: 3, years: '2016', episodes: '1-10', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Fate - The Winx Saga', seasons: [
        { number: 1, years: '2021', episodes: '1-6', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Friends', seasons: [
        { number: 1, years: '1994-95', episodes: '1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24', downloadStatus: 'incomplete' },
        { number: 2, years: '1995-96', episodes: '1-24', downloadStatus: 'complete' },
        { number: 3, years: '1996-97', episodes: '1-25', downloadStatus: 'complete' },
        { number: 4, years: '1997-98', episodes: '1-24', downloadStatus: 'complete' },
        { number: 5, years: '1998-99', episodes: '1-24', downloadStatus: 'complete' },
        { number: 6, years: '1999-00', episodes: '1-25', downloadStatus: 'complete' },
        { number: 7, years: '2000-01', episodes: '1-24', downloadStatus: 'complete' },
        { number: 8, years: '2001-02', episodes: '1-24', downloadStatus: 'complete' },
        { number: 9, years: '2002-03', episodes: '1-24', downloadStatus: 'complete' },
        { number: 10, years: '2003-04', episodes: '1-18', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Futurama', seasons: [
        { number: 1, years: '1999', episodes: '1-13', downloadStatus: 'complete' },
        { number: 2, years: '1999-00', episodes: '1-19', downloadStatus: 'complete' },
        { number: 3, years: '2001-02', episodes: '1-22', downloadStatus: 'complete' },
        { number: 4, years: '2002-03', episodes: '1-18', downloadStatus: 'complete' },
        { number: 5, years: '2008-09', episodes: '1-16', downloadStatus: 'complete' },
        { number: 6, years: '2010-11', episodes: '1-26', downloadStatus: 'complete' },
        { number: 7, years: '2012-13', episodes: '1-26', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Game of Thrones', seasons: [
        { number: 1, years: '2011', episodes: '1-10', downloadStatus: 'complete' },
        { number: 2, years: '2012', episodes: '1-10', downloadStatus: 'complete' },
        { number: 3, years: '2013', episodes: '1-10', downloadStatus: 'complete' },
        { number: 4, years: '2014', episodes: '1-10', downloadStatus: 'complete' },
        { number: 5, years: '2015', episodes: '1-10', downloadStatus: 'complete' },
        { number: 6, years: '2016', episodes: '1-10', downloadStatus: 'complete' },
        { number: 7, years: '2017', episodes: '1-7', downloadStatus: 'complete' },
        { number: 8, years: '2019', episodes: '1-6', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Gravity Falls', seasons: [
        { number: 1, years: '2012-13', episodes: '1-20', downloadStatus: 'complete' },
        { number: 2, years: '2014-16', episodes: '1-20', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'How I Met Your Mother', seasons: [
        { number: 1, years: '2005-06', episodes: '1-22', downloadStatus: 'complete' },
        { number: 2, years: '2006-07', episodes: '1-22', downloadStatus: 'complete' },
        { number: 3, years: '2007-08', episodes: '1-20', downloadStatus: 'complete' },
        { number: 4, years: '2008-09', episodes: '1-24', downloadStatus: 'complete' },
        { number: 5, years: '2009-10', episodes: '1-24', downloadStatus: 'complete' },
        { number: 6, years: '2010-11', episodes: '1-24', downloadStatus: 'complete' },
        { number: 7, years: '2011-12', episodes: '1-24', downloadStatus: 'complete' },
        { number: 8, years: '2012-13', episodes: '1-24', downloadStatus: 'complete' },
        { number: 9, years: '2013-14', episodes: '1-24', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Impulse', seasons: [
        { number: 1, years: '2018', episodes: '1-10', downloadStatus: 'complete' },
        { number: 2, years: '2019', episodes: '1-10', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'iZombie', seasons: [
        { number: 1, years: '2015', episodes: '1-13', downloadStatus: 'complete' },
        { number: 2, years: '2015-16', episodes: '1-19', downloadStatus: 'complete' },
        { number: 3, years: '2017', episodes: '1-13', downloadStatus: 'complete' },
        { number: 4, years: '2018', episodes: '1-13', downloadStatus: 'complete' },
        { number: 5, years: '2019', episodes: '1-13', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Joey', seasons: [
        { number: 1, years: '2004-05', episodes: '1-24', downloadStatus: 'complete' },
        { number: 2, years: '2005-06', episodes: '1-22', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Krypton', seasons: [
        { number: 1, years: '2018', episodes: '1-10', downloadStatus: 'complete' },
        { number: 2, years: '2019', episodes: '1-10', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Limitless', seasons: [
        { number: 1, years: '2015-16', episodes: '1-22', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Love, Death & Robots', seasons: [
        { number: 1, years: '2019', episodes: '1-18', downloadStatus: 'complete' },
        { number: 2, years: '2021', episodes: '1-8', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Lucifer', seasons: [
        { number: 1, years: '2016', episodes: '1-13', downloadStatus: 'complete' },
        { number: 2, years: '2016-17', episodes: '1-18', downloadStatus: 'complete' },
        { number: 3, years: '2017-18', episodes: '1-26', downloadStatus: 'complete' },
        { number: 4, years: '2019', episodes: '1-10', downloadStatus: 'complete' },
        { number: 5, years: '2020-21', episodes: null, downloadStatus: 'incomplete' },
        { number: 6, years: '2021', episodes: null, downloadStatus: 'incomplete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Malcolm in the Middle', seasons: [
        { number: 1, years: '2000', episodes: '1-16', downloadStatus: 'complete' },
        { number: 2, years: '2000-01', episodes: '1-25', downloadStatus: 'complete' },
        { number: 3, years: '2001-02', episodes: '1-22', downloadStatus: 'complete' },
        { number: 4, years: '2002-03', episodes: '1-22', downloadStatus: 'complete' },
        { number: 5, years: '2003-04', episodes: '1-22', downloadStatus: 'complete' },
        { number: 6, years: '2004-05', episodes: '1-22', downloadStatus: 'complete' },
        { number: 7, years: '2005-06', episodes: '1-22', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Mythbusters', seasons: [
        { number: 1, name: '2003', years: '2003', episodes: '1-11', downloadStatus: 'complete' },
        { number: 2, name: '2004', years: '2004', episodes: '1-17', downloadStatus: 'complete' },
        { number: 3, name: '2005', years: '2005', episodes: '1-26', downloadStatus: 'complete' },
        { number: 4, name: '2006', years: '2006', episodes: '1-28', downloadStatus: 'complete' },
        { number: 5, name: '2007', years: '2007', episodes: '1-25', downloadStatus: 'complete' },
        { number: 6, name: '2008', years: '2008', episodes: '1-20', downloadStatus: 'complete' },
        { number: 7, name: '2009', years: '2009', episodes: '1-23', downloadStatus: 'complete' },
        { number: 8, name: '2010', years: '2010', episodes: '1-24', downloadStatus: 'complete' },
        { number: 9, name: '2011', years: '2011', episodes: '1-22', downloadStatus: 'complete' },
        { number: 10, name: '2012', years: '2012', episodes: '1-21', downloadStatus: 'complete' },
        { number: 11, name: '2013', years: '2013', episodes: '1-11', downloadStatus: 'complete' },
        { number: 12, name: '2014', years: '2014', episodes: '1-15', downloadStatus: 'complete' },
        { number: 13, name: '2015', years: '2015', episodes: '1-14', downloadStatus: 'complete' },
        { number: 14, name: '2016', years: '2016', episodes: '1-11', downloadStatus: 'incomplete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'New Girl', seasons: [
        { number: 1, years: '2011-12', episodes: '1-24', downloadStatus: 'complete' },
        { number: 2, years: '2012-13', episodes: '1-25', downloadStatus: 'complete' },
        { number: 3, years: '2013-14', episodes: '1-23', downloadStatus: 'complete' },
        { number: 4, years: '2014-15', episodes: '1-22', downloadStatus: 'complete' },
        { number: 5, years: '2016', episodes: '1-22', downloadStatus: 'complete' },
        { number: 6, years: '2016-17', episodes: '1-22', downloadStatus: 'complete' },
        { number: 7, years: '2018', episodes: '1-8', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Orange is the New Black', seasons: [
        { number: 1, years: '2013', episodes: '1-13', downloadStatus: 'complete' },
        { number: 2, years: '2014', episodes: '1-13', downloadStatus: 'complete' },
        { number: 3, years: '2015', episodes: null, downloadStatus: 'incomplete' },
        { number: 4, years: '2016', episodes: null, downloadStatus: 'incomplete' },
        { number: 5, years: '2017', episodes: null, downloadStatus: 'incomplete' },
        { number: 6, years: '2018', episodes: null, downloadStatus: 'incomplete' },
        { number: 7, years: '2019', episodes: null, downloadStatus: 'incomplete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Panic', seasons: [
        { number: 1, years: '2021', episodes: '1-10', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Parks and Recreation', seasons: [
        { number: 1, years: '2009', episodes: '1-6', downloadStatus: 'complete' },
        { number: 2, years: '2009-10', episodes: '1-24', downloadStatus: 'complete' },
        { number: 3, years: '2011', episodes: '1-16', downloadStatus: 'complete' },
        { number: 4, years: '2011-12', episodes: '1-22', downloadStatus: 'complete' },
        { number: 5, years: '2012-13', episodes: '1-22', downloadStatus: 'complete' },
        { number: 6, years: '2013-14', episodes: '1-22', downloadStatus: 'complete' },
        { number: 7, years: '2015', episodes: '1-13', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Pretty Little Liars', seasons: [
        { number: 1, years: '2010-11', episodes: '1-22', downloadStatus: 'complete' },
        { number: 2, years: '2011-12', episodes: '1-25', downloadStatus: 'complete' },
        { number: 3, years: '2012-13', episodes: '1-24', downloadStatus: 'complete' },
        { number: 4, years: '2013-14', episodes: '1-24', downloadStatus: 'complete' },
        { number: 5, years: '2014-15', episodes: '1-25', downloadStatus: 'complete' },
        { number: 6, years: '2015-16', episodes: '1-20', downloadStatus: 'complete' },
        { number: 7, years: '2016-17', episodes: '1-20', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Psych', seasons: [
        { number: 1, years: '2006-07', episodes: '1-15', downloadStatus: 'complete' },
        { number: 2, years: '2007-08', episodes: '1-16', downloadStatus: 'complete' },
        { number: 3, years: '2008-09', episodes: '1-16', downloadStatus: 'complete' },
        { number: 4, years: '2009-10', episodes: '1-16', downloadStatus: 'complete' },
        { number: 5, years: '2010', episodes: '1-16', downloadStatus: 'complete' },
        { number: 6, years: '2011-12', episodes: '1-16', downloadStatus: 'complete' },
        { number: 7, years: '2013', episodes: '1-14', downloadStatus: 'complete' },
        { number: 8, years: '2014', episodes: '1-10', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Rick and Morty', seasons: [
        { number: 1, years: '2013-14', episodes: '1-11', downloadStatus: 'complete' },
        { number: 2, years: '2015', episodes: '1-10', downloadStatus: 'complete' },
        { number: 3, years: '2017', episodes: '1-10', downloadStatus: 'complete' },
        { number: 4, years: '2019-20', episodes: '1-10', downloadStatus: 'complete' },
        { number: 5, years: '2021', episodes: '1-10', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Sense8', seasons: [
        { number: 1, years: '2015', episodes: '1-12', downloadStatus: 'complete' },
        { number: 2, years: '2017', episodes: '1-12', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Shark Tank', seasons: [
        { number: 1, years: '2009-10', episodes: null, downloadStatus: 'incomplete' },
        { number: 2, years: '2011', episodes: null, downloadStatus: 'incomplete' },
        { number: 3, years: '2012', episodes: null, downloadStatus: 'incomplete' },
        { number: 4, years: '2012-13', episodes: null, downloadStatus: 'incomplete' },
        { number: 5, years: '2013-14', episodes: null, downloadStatus: 'incomplete' },
        { number: 6, years: '2014-15', episodes: null, downloadStatus: 'incomplete' },
        { number: 7, years: '2015-16', episodes: null, downloadStatus: 'incomplete' },
        { number: 8, years: '2016-17', episodes: null, downloadStatus: 'incomplete' },
        { number: 9, years: '2017-18', episodes: '1-24', downloadStatus: 'complete' },
        { number: 10, years: '2018-19', episodes: '1-23', downloadStatus: 'complete' },
        { number: 11, years: '2019-20', episodes: '3', downloadStatus: 'incomplete' },
        { number: 12, years: '2020-21', episodes: null, downloadStatus: 'incomplete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Smallville', seasons: [
        { number: 1, years: '2001-02', episodes: '1-21', downloadStatus: 'complete' },
        { number: 2, years: '2002-03', episodes: '1-23', downloadStatus: 'complete' },
        { number: 3, years: '2003-04', episodes: '1-22', downloadStatus: 'complete' },
        { number: 4, years: '2004-05', episodes: '1-22', downloadStatus: 'complete' },
        { number: 5, years: '2005-06', episodes: '1-22', downloadStatus: 'complete' },
        { number: 6, years: '2006-07', episodes: '1-22', downloadStatus: 'complete' },
        { number: 7, years: '2007-08', episodes: '1-20', downloadStatus: 'complete' },
        { number: 8, years: '2008-09', episodes: '1-22', downloadStatus: 'complete' },
        { number: 9, years: '2009-10', episodes: '1-21', downloadStatus: 'complete' },
        { number: 10, years: '2010-11', episodes: '1-22', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'That 70\'s Show', seasons: [
        { number: 1, years: '1998-99', episodes: '1-25', downloadStatus: 'complete' },
        { number: 2, years: '1999-00', episodes: '1-26', downloadStatus: 'complete' },
        { number: 3, years: '2000-01', episodes: '1-25', downloadStatus: 'complete' },
        { number: 4, years: '2001-02', episodes: '1-27', downloadStatus: 'complete' },
        { number: 5, years: '2002-03', episodes: '1-25', downloadStatus: 'complete' },
        { number: 6, years: '2003-04', episodes: '1-25', downloadStatus: 'complete' },
        { number: 7, years: '2004-05', episodes: '1-25', downloadStatus: 'complete' },
        { number: 8, years: '2005-06', episodes: '1-22', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'The Big Bang Theory', seasons: [
        { number: 1, years: '2007-08', episodes: '1-17', downloadStatus: 'complete' },
        { number: 2, years: '2008-09', episodes: '1-23', downloadStatus: 'complete' },
        { number: 3, years: '2009-10', episodes: '1-23', downloadStatus: 'complete' },
        { number: 4, years: '2010-11', episodes: '1-24', downloadStatus: 'complete' },
        { number: 5, years: '2011-12', episodes: '1-24', downloadStatus: 'complete' },
        { number: 6, years: '2012-13', episodes: '1-24', downloadStatus: 'complete' },
        { number: 7, years: '2013-14', episodes: '1-24', downloadStatus: 'complete' },
        { number: 8, years: '2014-15', episodes: '1-24', downloadStatus: 'complete' },
        { number: 9, years: '2015-16', episodes: '1-24', downloadStatus: 'complete' },
        { number: 10, years: '2016-17', episodes: '1-24', downloadStatus: 'complete' },
        { number: 11, years: '2017-18', episodes: '1-24', downloadStatus: 'complete' },
        { number: 12, years: '2018-19', episodes: '1-24', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'The Boys', seasons: [
        { number: 1, years: '2019', episodes: '1-8', downloadStatus: 'complete' },
        { number: 2, years: '2020', episodes: '1-8', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'The Gifted', seasons: [
        { number: 1, years: '2017-18', episodes: '1-13', downloadStatus: 'complete' },
        { number: 2, years: '2018-19', episodes: '1-16', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'The Good Place', seasons: [
        { number: 1, years: '2016-17', episodes: '1-13', downloadStatus: 'complete' },
        { number: 2, years: '2017-18', episodes: '1-13', downloadStatus: 'complete' },
        { number: 3, years: '2018-19', episodes: '1-13', downloadStatus: 'complete' },
        { number: 4, years: '2019-20', episodes: '1-14', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'The Office', seasons: [
        { number: 1, years: '2005', episodes: '1-6', downloadStatus: 'complete' },
        { number: 2, years: '2005-06', episodes: '1-22', downloadStatus: 'complete' },
        { number: 3, years: '2006-07', episodes: '1-25', downloadStatus: 'complete' },
        { number: 4, years: '2007-08', episodes: '1-19', downloadStatus: 'complete' },
        { number: 5, years: '2008-09', episodes: '1-28', downloadStatus: 'complete' },
        { number: 6, years: '2009-10', episodes: '1-26', downloadStatus: 'complete' },
        { number: 7, years: '2010-11', episodes: '1-26', downloadStatus: 'complete' },
        { number: 8, years: '2011-12', episodes: '1-24', downloadStatus: 'complete' },
        { number: 9, years: '2012-13', episodes: '1-25', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'The Powerpuff Girls (1998)', seasons: [
        { number: 1, years: '1998-99', episodes: '1-24', downloadStatus: 'complete' },
        { number: 2, years: '1999-00', episodes: '1-26', downloadStatus: 'complete' },
        { number: 3, years: '2000-01', episodes: '1-24', downloadStatus: 'complete' },
        { number: 4, years: '2001-02', episodes: '1-10', downloadStatus: 'complete' },
        { number: 5, years: '2003-04', episodes: '1-33', downloadStatus: 'complete' },
        { number: 6, years: '2004-05', episodes: '1-18', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'The Purge', seasons: [
        { number: 1, years: '2018', episodes: '1-10', downloadStatus: 'complete' },
        { number: 2, years: '2019', episodes: '1-10', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'The Queen\'s Gambit', seasons: [
        { number: 1, years: '2020', episodes: '1-7', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'The Umbrella Academy', seasons: [
        { number: 1, years: '2019', episodes: '1-10', downloadStatus: 'complete' },
        { number: 2, years: '2020', episodes: '1-10', downloadStatus: 'complete' },
        { number: 3, years: '2022', episodes: null, downloadStatus: 'incomplete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Titans', seasons: [
        { number: 1, years: '2018', episodes: '1-11', downloadStatus: 'complete' },
        { number: 2, years: '2019', episodes: '1-13', downloadStatus: 'complete' },
        { number: 3, years: '2021', episodes: null, downloadStatus: 'incomplete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Two and a Half Men', seasons: [
        { number: 1, years: '2003-04', episodes: '1-24', downloadStatus: 'complete' },
        { number: 2, years: '2004-05', episodes: '1-24', downloadStatus: 'complete' },
        { number: 3, years: '2005-06', episodes: '1-24', downloadStatus: 'complete' },
        { number: 4, years: '2006-07', episodes: '1-24', downloadStatus: 'complete' },
        { number: 5, years: '2007-08', episodes: '1-19', downloadStatus: 'complete' },
        { number: 6, years: '2008-09', episodes: '1-24', downloadStatus: 'complete' },
        { number: 7, years: '2009-10', episodes: '1-22', downloadStatus: 'complete' },
        { number: 8, years: '2010-11', episodes: '1-16', downloadStatus: 'complete' },
        { number: 9, years: '2011-12', episodes: '1-24', downloadStatus: 'complete' },
        { number: 10, years: '2012-13', episodes: '1-23', downloadStatus: 'complete' },
        { number: 11, years: '2013-14', episodes: '1-22', downloadStatus: 'complete' },
        { number: 12, years: '2014-15', episodes: '1-16', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Veronica Mars', seasons: [
        { number: 1, years: '2004-05', episodes: '1-22', downloadStatus: 'complete' },
        { number: 2, years: '2005-06', episodes: '1-22', downloadStatus: 'complete' },
        { number: 3, years: '2006-07', episodes: '1-20', downloadStatus: 'complete' },
        { number: 4, years: '2019', episodes: '1-8', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Watchmen', seasons: [
        { number: 1, years: '2019', episodes: '1-9', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Westworld', seasons: [
        { number: 1, name: 'The Maze', years: '2016', episodes: '1-10', downloadStatus: 'complete' },
        { number: 2, name: 'The Door', years: '2018', episodes: '1-10', downloadStatus: 'complete' },
        { number: 3, name: 'The New World', years: '2020', episodes: '1-8', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'White Collar', seasons: [
        { number: 1, years: '2009-10', episodes: '1-14', downloadStatus: 'complete' },
        { number: 2, years: '2010-11', episodes: '1-16', downloadStatus: 'complete' },
        { number: 3, years: '2011-12', episodes: '1-16', downloadStatus: 'complete' },
        { number: 4, years: '2012-13', episodes: '1-16', downloadStatus: 'complete' },
        { number: 5, years: '2013-14', episodes: '1-13', downloadStatus: 'complete' },
        { number: 6, years: '2014', episodes: '1-6', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Wilfred', seasons: [
        { number: 1, years: '2011', episodes: '1-13', downloadStatus: 'complete' },
        { number: 2, years: '2012', episodes: '1-13', downloadStatus: 'complete' },
        { number: 3, years: '2013', episodes: '1-13', downloadStatus: 'complete' },
        { number: 4, years: '2014', episodes: '1-10', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'You', seasons: [
        { number: 1, years: '2018', episodes: '1-10', downloadStatus: 'complete' },
        { number: 2, years: '2019', episodes: '1-10', downloadStatus: 'complete' },
        { number: 3, years: '2021', episodes: '1-10', downloadStatus: 'complete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Young Sheldon', seasons: [
        { number: 1, years: '2017-18', episodes: '1-22', downloadStatus: 'complete' },
        { number: 2, years: '2018-19', episodes: '1-22', downloadStatus: 'complete' },
        { number: 3, years: '2019-20', episodes: '1-21', downloadStatus: 'complete' },
        { number: 4, years: '2020-21', episodes: null, downloadStatus: 'incomplete' },
    ] },
    { type: 'tvShow', origin: 'downloaded', name: 'Your Honor', seasons: [
        { number: 1, years: '2020-21', episodes: '1-10', downloadStatus: 'complete' },
    ] },
];

module.exports = database;
