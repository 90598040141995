import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class MoviePoster extends Component {
    static propTypes = {
        attributes: PropTypes.array,
        name: PropTypes.string.isRequired,
        origin: PropTypes.string.isRequired,
        posterWidth: PropTypes.number.isRequired,
        year: PropTypes.number.isRequired,
    };

    movieToImageName(name, year) {
        let newName = name.toLowerCase().replace(/[^a-z0-9\s]/g, '');
        newName = newName.replace(/\s+/g, '-');

        return `${newName}-${year}.jpg`;
    }

    render() {
        var { attributes = null, franchise, left, name, origin, posterHeight, posterWidth, top, year } = this.props;

        var _className = classnames('movie', { franchise });

        attributes = attributes || [];
        var in4K = false;
        if (attributes.includes('4K')) {
            in4K = true;
        }

        return (
            <div className={_className} style={{ height: posterHeight - 25, left, top, width: posterWidth }}>
                <div className='poster'>
                    <img src={`posters/movie-posters-tiny-png/${this.movieToImageName(name, year)}`} alt='' />
                    <div className='info'>
                        <span style={{ fontSize: '20px', marginBottom: '15px' }}>{name}</span>
                        <span>Origin: {origin}</span>
                    </div>
                </div>
                <div className='name'>{name} ({year})</div>
                {in4K && <div className='attribute_4K'>4K</div>}
            </div>
        );
    }
}

export default MoviePoster;
